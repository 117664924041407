import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import AddPetrolBunk from "./Petrol-bunks-add";
import axiosInstance from "../Services/axiosInstance";
import { useSelector } from "react-redux";

const PetrolBunk = () => {
    const LoginDetails = useSelector((state) => state.login);
    const [map, setMap] = useState(null);
    const [directionsService, setDirectionsService] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const [locations, setLocations] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableDataShowUi, setTableDataShowUi] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedAminities, setselectedAminities] = useState([])

    const amenitiesList = [
        { id: 'amenityDieselDiscount', value: 'Diesel Discounts', label: 'Diesel Discounts' },
        { id: 'amenityCNG', value: 'CNG Availability', label: 'CNG Availability' },
        { id: 'amenityParking', value: 'Spacious Truck Parking', label: 'Spacious Truck Parking' },
        { id: 'amenityToilet', value: 'Clean Toilets', label: 'Clean Toilets' },
        { id: 'amenityRestArea', value: 'Rest Areas', label: 'Rest Areas' },
        { id: 'amenityCardPayment', value: 'Credit/Debit Card Payments Accepted', label: 'Credit/Debit Card Payments Accepted' },
        { id: 'amenityFuelCardPayment', value: 'Diesel Fuel Card Payments Accepted', label: 'Diesel Fuel Card Payments Accepted' },
        { id: 'amenityAutomatedBills', value: 'Automated Bills with Pump Readings', label: 'Automated Bills with Pump Readings' },
        { id: 'amenityFreeAir', value: 'Free Commercial Air for Tyres', label: 'Free Commercial Air for Tyres' },
        { id: 'amenityATM', value: 'On-Site ATM', label: 'On-Site ATM' },
        { id: 'amenityOilForSale', value: 'Vehicle Oil for Sale', label: 'Vehicle Oil for Sale' },
        { id: 'amenityDrinkingWater', value: 'Drinking Water', label: 'Drinking Water' },
        { id: 'amenityPUC', value: 'Pollution Control Check (PUC) (Within 500m)', label: 'Pollution Control Check (PUC) (Within 500m)' },
        { id: 'amenityWaterWash', value: 'Water Wash Services (Within 500m)', label: 'Water Wash Services (Within 500m)' },
        { id: 'amenityPunctureRepair', value: 'Puncture Repair (Within 500m)', label: 'Puncture Repair (Within 500m)' },
        { id: 'amenityMaintenance', value: 'Minor Maintenance Services (Within 500m)', label: 'Minor Maintenance Services (Within 500m)' },
        { id: 'amenityWheelAlignment', value: 'Wheel Alignment (Within 500m)', label: 'Wheel Alignment (Within 500m)' },
        { id: 'amenityWeighbridge', value: 'Weighbridge (Within 500m)', label: 'Weighbridge (Within 500m)' },
        { id: 'amenityGeneralStore', value: 'General Stores (Within 500m)', label: 'General Stores (Within 500m)' },
        { id: 'amenityHotelsDhabas', value: 'Hotels/Dhabas (Within 500m)', label: 'Hotels/Dhabas(Within 500m)' },
    ];

    const initMap = (alterResponse) => {
        const googleMap = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 20.5937, lng: 78.9629 },
            zoom: 5,
        });

        const service = new window.google.maps.DirectionsService();
        const renderer = new window.google.maps.DirectionsRenderer();
        renderer.setMap(googleMap);

        setMap(googleMap);
        setDirectionsService(service);
        setDirectionsRenderer(renderer);

        addExampleLocations(googleMap, alterResponse);
    };

    useEffect(() => {
        let mapInstance = null;

        const fetchData = async () => {
            try {
                const { data } = await axiosInstance.post("/get_petrol_bunk_details", {});

                if (data?.error_code === 0) {
                    const alterResponse = data?.data?.map((val) => {
                        let name = val?.petrol_bunk_name || ''
                        let owner_name = val?.owner_name || ''
                        let lat = Number(val?.latitude) || 0
                        let lng = Number(val?.longitude) || 0
                        let address = val?.location || ''
                        let amenities = val?.amenities?.join(",") || ''
                        let discounts = val?.discounts?.join(",") || ''

                        return { name, owner_name, lat, lng, address, amenities, discounts }
                    })

                    mapInstance = initMap(alterResponse || []); // Initialize the map
                }
            } catch (err) {
                toast.error(err?.message || "An error occurred");
            }
        };

        fetchData();

        // Cleanup function to destroy the map instance
        return () => {
            if (mapInstance && typeof mapInstance.destroy === "function") {
                mapInstance.destroy(); // If your map library uses destroy()
            } else if (mapInstance && typeof mapInstance.remove === "function") {
                mapInstance.remove(); // If your map library uses remove()
            }
        };
    }, []);

    const addExampleLocations = (googleMap, alterResponse) => {
        const markers = alterResponse?.map((location) => {
            const marker = new window.google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map: googleMap,
                title: location.name,
            });

            const infoWindow = new window.google.maps.InfoWindow({
                content: `<b>${location.name}</b><br>${location.amenities}<br>${location.discounts}`,
            });

            marker.addListener('click', () => {
                infoWindow.open(googleMap, marker);
            });

            return { ...location, marker };
        });

        setLocations(markers);
    };

    useEffect(() => {
        const setupAutocomplete = (id, callback) => {
            const inputElement = document.getElementById(id);
            const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
                componentRestrictions: { country: 'IN' },
            });
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    callback(place.geometry.location);
                }
            });
        };

        setupAutocomplete('from-location', (location) =>
            console.log('From Location:', location.lat(), location.lng())
        );
        setupAutocomplete('to-location', (location) =>
            console.log('To Location:', location.lat(), location.lng())
        );
    }, []);

    function generateRouteLink(lat, lng) {
        const from = document.getElementById('from-location').value;

        if (!from) {
            alert('Please enter a starting location in the "From Location" field.');
            return;
        }

        const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(from)}&destination=${lat},${lng}&travelmode=driving`;

        // Open the directions URL in a new tab
        window.open(directionsUrl, '_blank');
    }

    function calculateDistance(lat1, lng1, lat2, lng2) {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLng = (lng2 - lng1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c * 1000; // Convert to meters
    }

    const findRoute = () => {
        const from = document.getElementById('from-location').value;
        const to = document.getElementById('to-location').value;

        if (!from || !to) {
            toast.error('Please enter both From and To locations.');
            return;
        }

        const request = {
            origin: from,
            destination: to,
            travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                directionsRenderer.setDirections(result);

                const nearbyLocations = [];
                const proximityThreshold = 5000; // Set proximity level to 5 kilometers

                locations.forEach((location) => {
                    // Check if location is within proximity to the route path
                    const isNearby = result.routes[0].overview_path.some((point) => {
                        const distance = calculateDistance(
                            point.lat(),
                            point.lng(),
                            location.lat,
                            location.lng
                        );
                        return distance <= proximityThreshold;
                    });

                    if (isNearby) {
                        nearbyLocations.push(location);
                        location.marker.setMap(map); // Show marker on the map
                    } else {
                        location.marker.setMap(null); // Hide marker from the map
                    }
                });

                // Sort nearby locations based on their proximity to the start of the route
                nearbyLocations.sort((a, b) => {
                    const distanceA = calculateDistance(
                        result.routes[0].overview_path[0].lat(),
                        result.routes[0].overview_path[0].lng(),
                        a.lat,
                        a.lng
                    );
                    const distanceB = calculateDistance(
                        result.routes[0].overview_path[0].lat(),
                        result.routes[0].overview_path[0].lng(),
                        b.lat,
                        b.lng
                    );
                    return distanceA - distanceB;
                });

                setTableDataShowUi(nearbyLocations);
                setTableData(nearbyLocations);
            } else {
                toast.error('Could not display route. Please try again.');
            }
        });
    };

    function applyFilters() {
        const selectedAmenities = Array.from(document.querySelectorAll('.amenities:checked'))?.map(input => input.value);
        setselectedAminities(selectedAmenities)
        const discount = document.getElementById('discounts')?.value?.trim();

        const filteredLocations = tableData.filter(location => {
            // Check if location contains all selected amenities
            const hasAmenities = selectedAmenities.every(amenity =>
                location.amenities.toLowerCase().includes(amenity.toLowerCase())
            );

            // // Check if discounts match (if entered)
            const matchesDiscounts = !discount || location.discounts.toLowerCase().includes(discount.toLowerCase());
            return hasAmenities && matchesDiscounts;
        });
        setTableDataShowUi(filteredLocations);

        // Update map markers based on filtered locations
        // Update markers on the map for filtered locations
        locations.forEach(location => {
            if (filteredLocations.includes(location)) {
                location.marker.setMap(map); // Show marker
            } else {
                location.marker.setMap(null); // Hide marker
            }
        });
        setLocations(locations);

        setShow(false)
    }

    function handleSelectAminities(e) {
        if (selectedAminities?.includes(e.target.value)) {
            const removeAminity = selectedAminities?.filter((v) => v !== e.target.value)
            setselectedAminities(removeAminity)
        } else {
            setselectedAminities([...selectedAminities, e.target.value])
        }
    }

    function handleClearFilter() {
        setShow(false)
        setselectedAminities([])
        findRoute()
    }

    return (
        <div className="container">
            <div className='col-lg-12 '>
                <Modal show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Petrol Bunk</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form id="petrolBunkForm">
                            {/* Amenities */}
                            <div className="form-group">
                                <label>Amenities (within 5 kms):</label>
                                {amenitiesList.map((amenity) => (
                                    <div className="form-check" key={amenity.id}>
                                        <input
                                            className="form-check-input amenities"
                                            type="checkbox"
                                            id={amenity.id}
                                            name="amenities"
                                            value={amenity.value}
                                            onClick={handleSelectAminities}
                                            checked={selectedAminities?.includes(amenity.value)}
                                        />
                                        <label className="form-check-label" htmlFor={amenity.id}>
                                            {amenity.label}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            {/* Discounts */}
                            <div className="form-group">
                                <label htmlFor="discounts">Discounts per liter:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="discounts"
                                    name="discounts"
                                    placeholder="Enter discounts (if any)"
                                />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="w-100 row">
                        <div className="col">
                            <Button variant="secondary" onClick={handleClearFilter}> Clear Filter </Button>
                        </div>
                        <div className="col">
                            <Button variant="primary" onClick={applyFilters}>Filter</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>

            <div className="row">
                <div className="col-lg-8">
                    <div id="map" style={{ height: '500px', width: '100%' }}></div>
                </div>
                <div className="col-lg-4">
                    {LoginDetails.isLoggedIn ?
                        <AddPetrolBunk setRefresh={setRefresh} refresh={refresh} />
                        :
                        <button type="button " className='cardbutton truck-brand-button ' data-bs-toggle="modal" data-bs-target="#loginModal">Add Your Petrol Bunk</button>
                    }

                    <hr></hr>
                    <h2>Route Finder</h2>
                    <input id="from-location" className="form-control mb-3" placeholder="From Location" />
                    <input id="to-location" className="form-control mb-3" placeholder="To Location" />
                    <button className="btn btn-success mb-2" onClick={findRoute}>Find Route</button>
                    <button className="btn btn-warning mb-2" onClick={handleShow}>Filter</button>
                </div>
            </div>
            <div className="mt-4">
                {tableDataShowUi.length ?
                    <table className="table table-bordered">
                        <thead className="table-dark">
                            <tr>
                                <th>Name</th>
                                <th>Coordinates</th>
                                <th>Amenities</th>
                                <th>Discounts</th>
                                <th>Route</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableDataShowUi.length ? tableDataShowUi.map((loc, idx) => (
                                <tr key={idx}>
                                    <td>{loc?.name}</td>
                                    <td>{loc?.lat}, {loc?.lng}</td>
                                    <td>{loc?.amenities}</td>
                                    <td>{loc?.discounts}</td>
                                    <td>
                                        <button type="button" className='btn btn-dark' onClick={() => generateRouteLink(loc?.lat, loc?.lng)}>Get Route</button>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan="4" className="text-center">No locations found</td></tr>}
                        </tbody>
                    </table>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default PetrolBunk;