import React from 'react';
import MyAccount from './shop-components/my-account';


const MyaccountV1 = () => {
    return <div>
        <MyAccount />
    </div>
}

export default MyaccountV1

