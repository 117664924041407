import React from 'react';
import BlogLeft from './blog-components/blog-list';


const BlogLeftSidebar = () => {
    return <div>
        <BlogLeft />
    </div>
}

export default BlogLeftSidebar

