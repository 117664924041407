import React from 'react';
import BlogGrid from './blog-components/blog-grid';

const BlogGridPage = () => {
    return <div>
        {/* <PageHeader headertitle="Blog Grid" /> */}
        <BlogGrid />
        {/* <CallToActionV1 /> */}
    </div>
}

export default BlogGridPage

