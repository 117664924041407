import React from 'react';
import PortfolioV1 from './section-components/portfolio-v1';


const Portfolio_V1 = () => {
    return <div>
        <PortfolioV1 />
    </div>
}

export default Portfolio_V1

