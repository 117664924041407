import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { decryptData, encryptData } from "../../Security/Crypto/Crypto";
import axiosInstance from "../../Services/axiosInstance";
import toast from "react-hot-toast";
import { IoArrowBack } from "react-icons/io5";

const ChatView = () => {
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [smallDeciveCheck, setsmallDeciveCheck] = useState(false)
  const [showChatlist, setshowChatlist] = useState(false)
  const [smallDevice, setSmallDevice] = useState(false)
  const userId = Cookies.get("usrin"); // Set your user_id
  const [personId, setPersonId] = useState(''); // Will be set based on selected conversation
  const [activeUserData, setActiveUserData] = useState({})
  const [userList, setUserList] = useState([]);
  const chatWindowRef = useRef(null); // To reference the chat window

  //finding minimum devices and updating the state when screen adjustment
  useEffect(() => {
    if (personId) {
      if (window.innerWidth <= 992) {
        navigate(`/chat?person_id=${encryptData(personId)}&resposive=true&showChatlist=true`)
        setsmallDeciveCheck(true)
      } else {
        navigate(`/chat?person_id=${encryptData(personId)}&resposive=false&showChatlist=false`)
        setsmallDeciveCheck(false)
      }

      const handleResize = () => {
        if (window.innerWidth <= 992) {
          navigate(`/chat?person_id=${encryptData(personId)}&resposive=true&showChatlist=true`)
          setsmallDeciveCheck(true)
        } else {
          navigate(`/chat?person_id=${encryptData(personId)}&resposive=false&showChatlist=false`)
          setsmallDeciveCheck(false)
        }
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [])

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }

    const urlpersonId = decryptData(params.get('person_id'))
    const resposive = params.get('resposive') || false
    if (resposive == 'true') {
      setSmallDevice(true)
    } else {
      setSmallDevice(false)
    }

    const showChatlist = params.get('showChatlist') || false
    if (showChatlist == 'true') {
      setshowChatlist(false)
    } else {
      setshowChatlist(true)
    }

    console.log(urlpersonId, "urlpersonId")
    if (urlpersonId) {
      if (urlpersonId !== "Invalid id") {
        console.log(urlpersonId)
        setPersonId(urlpersonId)
        fetchChatMessages(urlpersonId);
      }
      else {
        toast.error(urlpersonId)
      }
    }
  }, [params.get('person_id'), params.get('resposive'), params.get('showChatlist')])

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = async () => {
    try {
      const response = await axios.post("https://truck.truckmessage.com/get_user_chat_list", { user_id: window.atob(userId) });

      if (response.data.error_code === 0) {
        const getActiveUser = response.data.data?.find((user) => user?.person_id === personId)
        setActiveUserData(getActiveUser)
        setUserList(response.data.data)
      }
    } catch (err) {
      console.error("Error getting user list:", err);
    }
  };

  const fetchChatMessages = async (personIdProps) => {
    try {
      const response = await axiosInstance.post("/get_user_chat_message_list", {
        user_id: window.atob(userId),
        person_id: personIdProps,
      });

      if (response.data.error_code === 0) {
        setMessages(response.data.data.reverse());
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Function to handle sending a message
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        const response = await axios.post(
          "https://truck.truckmessage.com/user_chat_message",
          {
            user_id: window.atob(userId),
            person_id: personId,
            message: newMessage,
          }
        );

        if (response.data.error_code === 0) {
          // Add sent message to the message list
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              chat_id: window.atob(userId),
              message: newMessage,
              updt: new Date().toUTCString(),
            },
          ]);

          getUserList()
          setNewMessage("");
        } else {
          console.error("Error sending message:", response.data.message);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  function handleNextchat(person_id) {
    const getActiveUser = userList?.find((user) => user?.person_id === person_id)
    setActiveUserData(getActiveUser)
    navigate(`/chat?person_id=${encryptData(person_id)}&resposive=${smallDeciveCheck ? 'true' : 'false'}&showChatlist=false`)
  }

  return (
    <div className="container-fluid d-flex flex-column py-3">
      <div className="row flex-grow-1">
        {/* Conversation List */}
        <div className={`${smallDevice && !showChatlist ? 'w-100' : 'col-md-4'} border-end border-top conversation-list ${smallDevice && showChatlist ? "d-none" : ''}`}>
          <h5 className="card-title chatlisthead mt-3 position-sticky top-0">Chat List</h5>
          <div className="list-group pt-3">
            {userList.map((users, userIndex) => {
              return (
                <button key={userIndex} className={`chat-person-card mb-2 d-flex align-items-center ${users.person_id == personId ? "active" : ""}`} onClick={() => handleNextchat(users.person_id)} style={{ cursor: "pointer" }}>
                  <img src={users.profile_image_name} width="40" height="40" className="rounded-circle img1" alt="User" />
                  <div className="ms-3 text-start">
                    <div className="fw-bold">
                      {users.profile_name}
                    </div>
                    <div className="text-muted">
                      {users.last_msg}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>

        {/* Chat Window */}
        <div className={`${smallDevice && showChatlist ? "w-100" : 'col-md-8'} border-top ${!showChatlist ? 'd-none' : 'd-flex flex-column'}`}>
          <div className="col-md-12 row d-flex align-items-center gap-2">
            {
              smallDevice && showChatlist ?
                <div className="chatlistheadcon row align-items-center">
                  <div className="col-1 text-center">
                    <span onClick={() => navigate(`/chat?person_id=${encryptData(personId)}&resposive=true&showChatlist=true`)}>
                      <IoArrowBack className="fs-3 cursorPointer" />
                    </span>
                  </div>
                  <div className="col-11">
                    <img src={activeUserData?.profile_image_name} width="40" height="40" className="rounded-circle img1" alt="User" />
                    <h6 className="d-inline ps-3">{activeUserData?.profile_name}</h6>
                  </div>
                </div>
                :
                <h5 className="col-md-12 card-title chatlistheadcon mt-3 pe-2">
                  Conversations
                </h5>
            }
          </div>
          <div className="flex-grow-1 p-3 chat-window d-flex flex-column chat-messgae-min-height" ref={chatWindowRef}>
            {messages.map((msg, index) => {
              return (
                <div key={index} className={`message col-12 row flex-row mb-3 ${msg.chat_id == window.atob(userId) ? "text-end" : "text-start"}`}>
                  {
                    msg.chat_id != window.atob(userId) ?
                      <div className="col-1 text-center">
                        <img src="https://img.icons8.com/color/40/000000/guest-female.png" height="40" className="rounded-circle " alt="User" />
                      </div>
                      :
                      null
                  }

                  <div className="col-11">
                    <div className={`message-content col-6 p-2 rounded ${msg.chat_id == window.atob(userId) ? "bg-primary text-white text-start" : "bg-light text-dark"}`} >
                      <div>{msg.message}</div>
                      <div className="small">
                        {new Date(msg.updt).toLocaleString()}
                      </div>
                    </div>
                  </div>

                  {
                    msg.chat_id == window.atob(userId) ?
                      <div className="col-1 text-center">
                        <img src="https://img.icons8.com/color/40/000000/guest-female.png" height="40" className="rounded-circle img1" alt="User" />
                      </div>
                      :
                      null
                  }
                </div>
              );
            })}
          </div>
          <div className="p-3 border-top">
            <div className="input-group position-relative">
              <input type="text" className="form-control" placeholder="Type your message..." value={newMessage} onChange={(e) => setNewMessage(e.target.value)} onKeyPress={(e) => e.key === "Enter" && handleSendMessage()} />
              <div className="send-message-btn">
                <button className="btn btn-primary" onClick={handleSendMessage}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatView;