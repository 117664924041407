import React from 'react';
import TruckAvailability from './blog-components/truckavailability';

const BlogDetailsPage = () => {
    return <div> 
        <TruckAvailability /> 
    </div>
}

export default BlogDetailsPage

