import React from 'react'
import MyAccount from './shop-components/my-account'

const Profile = () => {
  return (
    <>
        {/* <Page_header headertitle="My profile" /> */}
        <MyAccount/>
    </>
  )
}

export default Profile