import React from 'react';
import Wishlist from './section-components/wishlist';


const WishlistPage = () => {
    return <div>
        <Wishlist />
    </div>
}

export default WishlistPage

